import router from "../../router";
import authApi from "../../api/auth-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  namespaced: true,
  state: { showModalDisclaimer: false },
  data() {
    return { objLogin: null };
  },
  mutations: {
    recoverUser(state) {
      const recoveredUser = JSON.parse(localStorage.getItem("user"));
      if (!recoveredUser) {
        state.status = null;
        state.user = null;
      } else {
        state.status = { loggedIn: true };
        state.user = recoveredUser;
      }
    },
    refreshTokenSuccess(state, responseData) {
      state.user.token = responseData.token;
      // eslint-disable-next-line no-mixed-operators
      state.user.expires_in = new Date(
        new Date().getTime() + responseData.expires_in * 60 * 1000
      ).getTime();
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    loginSuccess(state, responseData) {
      state.status = { loggedIn: true };
      state.user = {
        usuario: responseData[0].USUARIO,
        nombre: responseData[0].NOMBRE,
        rol_id: responseData[0].ROL_ID,
        rol_nombre: responseData[0].ROL_NOMBRE,
        id_agente: responseData[0].ID_AGENTE,
        token: responseData[1].token,
        // eslint-disable-next-line no-mixed-operators
        expires_in: new Date(
          new Date().getTime() + responseData[1].expires_in * 60 * 1000
        ).getTime(),
      };

      state.loginFailed = false;

      //copiamos la información del usuario al localStorage
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    logout(state) {
      localStorage.removeItem("user");
      localStorage.removeItem("cart");
      localStorage.removeItem("currentCustomer");
      localStorage.removeItem("searchProd");
      localStorage.removeItem("familyProd");
      localStorage.removeItem("sizeProd");
      localStorage.removeItem("currentPageProd");
      localStorage.removeItem("sortByProd");
      localStorage.removeItem("sortDescProd");

      localStorage.removeItem("PerPageOrder");
      localStorage.removeItem("fechaIniOrder");
      localStorage.removeItem("fechaFinOrder");
      localStorage.removeItem("currentPageOrder");
      localStorage.removeItem("sortByOrder");
      localStorage.removeItem("sortDescOrder");
      state.status = {};
      state.user = null;
      router.push({ name: "login" });
    },
    setShowModalDisclaimer(state, payload) {
      state.showModalDisclaimer = payload;
      console.log(state.showModalDisclaimer);
    },
  },
  actions: {
    checkToken({ commit, dispatch }) {
      const user = JSON.parse(localStorage.getItem("user"));
      const current = new Date().getTime();
      if (user) {
        if (current >= user.expires_in) {
          commit("notifications", {
            title: "Sesión expirada, vuelva a iniciar sesión.",
            color: "danger",
          });
          router.push({ name: "login" });
        } else if (user.expires_in - current < 1800000) {
          dispatch("refreshToken");
        }
      } else {
        router.push({ name: "login" });
      }
    },
    refreshToken({ commit }) {
      authApi.refreshToken().then((response) => {
        commit("refreshTokenSuccess", response.data);
      });
    },
    acceptedConditions({ commit }) {
      console.log(this.objLogin);
      commit("loginSuccess", this.objLogin);
      location.href = "/admin";
    },
    login(
      { commit, dispatch },
      { username, password, setShowModalDisclaimer, toast }
    ) {
      authApi.login(username, password).then(
        (response) => {
          var obj = {
            usuario: response.data[0].USUARIO,
            nombre: response.data[0].NOMBRE,
            rol_id: response.data[0].ROL_ID,
            rol_nombre: response.data[0].ROL_NOMBRE,
            id_agente: response.data[0].ID_AGENTE,
            token: response.data[1].token,
          };

          this.objLogin = response.data;

          if (obj.rol_id == 2 || obj.rol_id == 3) {
            setShowModalDisclaimer(true);
          } else {
            dispatch("acceptedConditions");
          }

          /*   commit("loginSuccess", response.data);
          location.href = "/admin"; */
        },
        (error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response);
            let textError = 'Usuario y/o contraseña incorrectos'
            if (error.response.data.Error) {
              textError = error.response.data.Error
            }
            toast({
              component: ToastificationContent,
              props: {
                title: textError,
                icon: "EditIcon",
                variant: "light",
              },
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        }
      );
    },
    forgot({ commit, dispatch }, { email, setShowModalDisclaimer, toast }) {
      authApi.forgot(email).then(
        (response) => {
          toast({
            component: ToastificationContent,
            props: {
              title:
                "Si el usuario está dado de alta recibirá un correo con las instrucciones",
              icon: "EditIcon",
              variant: "light",
            },
          });
        },
        (error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response);
            toast({
              component: ToastificationContent,
              props: {
                title:
                  "Si el usuario está dado de alta recibirá un correo con las instrucciones",
                icon: "EditIcon",
                variant: "light",
              },
            });
            /*  toast({
              component: ToastificationContent,
              props: {
                title:
                  "Si el usuario está dado de alta recibirá un correo con las instrucciones",
                icon: "EditIcon",
                variant: "light",
              },
            }); */
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        }
      );
    },
    newUser({ commit, dispatch }, { email, setShowModalDisclaimer, toast }) {
      authApi.newUser(email).then(
        (response) => {
          toast({
            component: ToastificationContent,
            props: {
              title:
                "Gracias, nuestros comerciales contactarán con usted para darle acceso a la aplicación",
              icon: "EditIcon",
              variant: "light",
            },
          });
        },
        (error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response);
            toast({
              component: ToastificationContent,
              props: {
                title: "Ha ocurrido un error",
                icon: "CrossIcon",
                variant: "danger",
              },
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        }
      );
    },
    logout({ commit }) {
      commit("logout");
      router.push({ name: "login" });
    },
  },
  getters: {
    getCurrentUsername: (state) => state.user.usuario,
    getUser: (state) => state.user,
    getShowModalDisclaimer: (state) => state.showModalDisclaimer,
  },
};
