import familiesApi from "../../api/families-api";

export default {
  namespaced: true,
  state: {
    listFamilies: [],
  },
  mutations: {
    setListFamilies(state, payload) {
      state.listFamilies = payload.data;
    },
  },
  actions: {
    async searchFamilies({ commit }, {size = '', search = ''}) {
      const res = await familiesApi.getFamilies(size, search);

      const array = [
        {
          text: "Todos",
          value: '',
        },
      ];
      res.data.forEach((element) => {
        array.push(element.Familia);
      });
      res.data = array;
      commit("setListFamilies", res);
    },
  },
  getters: {
    getListFamilies: (state) => state.listFamilies,
  },
};
