import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/sizes";

export default {
  getSizes(family = '', search = '') {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}?familia=${family}&search=${search}`, {});
  },
};
