import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/auth";

export default {
  login(username, password) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/login`, {
      username,
      password,
      platform: "WEB",
    });
  },
  forgot(email) {
    return axios.post(
      `${config.webApiBase + RESOURCE_NAME}/reset-password-email`,
      {
        email,
      }
    );
  },
  newUser(email) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/register-user`, {
      email,
    });
  },
  refreshToken() {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/refreshToken`);
  },
  recoverPassword(email) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/password/email`, {
      email,
    });
  },
  resetPassword(email, password, passwordConfirmation, token) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/password/reset`, {
      email,
      password,
      passwordConfirmation,
      token,
    });
  },
};
