import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/familia";

export default {
  getFamilies(format = '', search = '') {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}?formato=${format}&search=${search}`, {});
  },
};
